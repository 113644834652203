.image {
    margin: $medium-gap auto;
    text-align: center;

    &-link {
        display: inline-block;
        border: $border-width $border-style $border-color;
    }

    &-media {
        width: 100%;
        max-width: $media-preview-width;
        background: $bg-color;

        //  variations
        &-antialiased { @include antialiased; }
        &-pixelated { @include pixelated; }
        &-wide { max-width: none; }
    }
}
