// colors
$bg-color: #000000;
$meta-color: #eb75ff;
$heading-color: #ff8bff;
$text-color: #7cffff;
$link-color: #a466ff;
$code-color: #00ff7f;
$border-color: #9999ff;
$table-colors: #7cff8b, #66a4ff, #eb75ff, #ff8080, #e6ff3f;
$nav-colors: #e6ff3f, #ffa93f, #ff6161, #df3fff, #7478ff, #3fcbff, #3fffdf, #3fff78;

// fonts
$heading-font-sizes: 2em, 1.5em, 1.17em, 1em, 0.83em, 0.75em;
$heading-line-height: 1.0;
$text-font-family: "Comic Neue Bold", "Comic Sans", sans-serif;
$text-font-size: 15pt;
$text-line-height: 1.1;
$code-font-family: "Courier Prime", "Courier New", monospace;
$code-font-size: 14pt;
$code-line-height: 1.0;

// gaps
$narrow-gap: 4px;
$medium-gap: 16px;
$wide-gap: 32px;

// borders
$border-width: 2px;
$border-style: ridge;

// effects
$disabled-opacity: 0.5;

// media queries
$column-width: 800px;
$small-viewport-width: $column-width + 2 * $medium-gap;
$small-media-query: "only screen and (max-width: #{$small-viewport-width})";

// media constraints
$media-preview-width: 480px;
