.accent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: $medium-gap;
    margin: $medium-gap auto;

    &-column {
        flex: 0 1 auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: $medium-gap;
    }

    &-image {
        flex: 0 1 auto;
        max-width: 100%;
    }
}
