@mixin pixelated {
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
}

@mixin antialiased {
    -ms-interpolation-mode: bicubic;
    image-rendering: auto;
}
