.icon-preview {
    $icon-size: 128px;
    
    overflow: hidden;

    // flair modifiers
    &-dead { opacity: $disabled-opacity; }

    &-header {
        margin-bottom: $medium-gap;
    }

    // left side icon
    &-icon {
        position: relative;
        float: left;
        width: $icon-size;
        height: $icon-size;
        margin: $narrow-gap $medium-gap $narrow-gap 0;
    }
    &-flair {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &-title {
        margin: 0;
        font-size: 1.5em;
    }
    &-excerpt {
        margin: 0;
    }
}
