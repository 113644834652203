.heading {
    &-title {
        display: inline;
        margin: 0;
    }
    &-flair {
        display: inline-block;

        // size variations
        &-1 { height: nth($heading-font-sizes, 1); }
        &-2 { height: nth($heading-font-sizes, 2); }
        &-3 { height: nth($heading-font-sizes, 3); }
        &-4 { height: nth($heading-font-sizes, 4); }
        &-5 { height: nth($heading-font-sizes, 5); }
        &-6 { height: nth($heading-font-sizes, 6); }

        // nested flair element
        > .flair {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &-title, &-flair {
        vertical-align: middle;
    }

    // size variations
    &-2, &-3, &-4, &-5, &-6 {
        margin: $wide-gap 0 $medium-gap;
    }
}
