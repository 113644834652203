body {
    margin: 0;
    padding: 0;
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

img, svg {
    vertical-align: middle;
    border: 0;
}
