@font-face {
    font-family: "Comic Neue Bold";
    src: url("comic-neue-bold.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Courier Prime";
    src: url("courier-prime.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

body {
    color: $text-color;
    background: url("bg.gif") top center $bg-color;
    font-family: $text-font-family;
    font-size: $text-font-size;
    line-height: 1.0;

    @include pixelated;
}

::selection {
    color: $bg-color;
    background: $text-color;
}


// headings
h1 { font-size: nth($heading-font-sizes, 1); }
h2 { font-size: nth($heading-font-sizes, 2); }
h3 { font-size: nth($heading-font-sizes, 3); }
h4 { font-size: nth($heading-font-sizes, 4); }
h5 { font-size: nth($heading-font-sizes, 5); }
h6 { font-size: nth($heading-font-sizes, 6); }
h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
    margin: $wide-gap 0 $medium-gap;
    padding: 0;
    font-weight: bold;
    line-height: $heading-line-height;
}


// links
a {
    color: $link-color;
    text-decoration: underline;
}

// block elements
p, ol, ul, blockquote {
    line-height: $text-line-height;
}
p, ol, ul, pre, blockquote, hr {
    margin-top: $medium-gap;
    margin-bottom: $medium-gap;
}
ul, ol {
    padding-left: $wide-gap;
}
ul {
    list-style-image: url('bullet.gif');
}
pre {
    background: $bg-color;
    border: $border-width $border-style $border-color;
    overflow: auto;
    padding: $narrow-gap;
    line-height: $code-line-height;
}
pre, code {
    color: $code-color;
    font-family: $code-font-family;
    font-size: $code-font-size;
}
blockquote {
    margin-left: $wide-gap;
    margin-right: $wide-gap;
}
hr {
    border: none;
}

// tables
table {
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
    //margin: $medium-gap 0;

    position: relative;
    left: 50%;
    transform: translateX(-50%);

    table-layout: fixed;
    border-collapse: collapse;
}
th {
    color: $border-color;
    font-weight: bold;
    text-align: center;
}
th, td {
    background: $bg-color;
    border: $border-width $border-style $border-color;
    padding: $narrow-gap;
}

// generate style for each table color
$table-colors-length: length($table-colors);
@for $i from 1 through $table-colors-length {
    tr:nth-child(#{$table-colors-length}n+#{$i}) { color: nth($table-colors, $i); }
}

tr:nth-child(odd) {
    //color: $table-odd-color;
}
tr:nth-child(even) {
    //color: $table-even-color;
}
