.inline-document {
    margin: $wide-gap 0;

    &-header {
        margin-bottom: $medium-gap;
    }
    &-title {
        margin: 0;
        font-size: nth($heading-font-sizes, 2);
    }
    &-date {
        color: $meta-color;
        line-height: $text-line-height;
    }
    &-excerpt {
        margin: 0;
        display: inline;
    }
    &-footer {
        display: inline;
        //margin-top: $medium-gap;
        //text-align: right;
    }
}
