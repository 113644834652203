
// site layout
.base {
    max-width: $column-width;
    margin: 0 auto;
    padding: $medium-gap;
    overflow-x: hidden;
}
.header {
    color: $meta-color;
    text-align: center;
    position: relative;

    &-junk {
        position: absolute;

        @media #{$small-media-query} {
            display: none;
        }

        &-mercury       { left: -150px; top:  20px; }
        &-venus         { left: -110px; top:  60px; }
        &-mars          { left:  -30px; top:   5px; }
        &-jupiter       { left:   30px; top:  65px; }
        &-shuttle       { left:   70px; top:   5px; }
        &-alien         { left:  -30px; top:  90px; }
        &-asteroid-1    { left:  -95px; top:   5px; }

        &-saturn        { right:  -90px; top:   0px; }
        &-uranus        { right:   30px; top: 105px; }
        &-neptune       { right: -100px; top:  70px; }
        &-pluto         { right: -150px; top:  30px; }
        &-satellite     { right:  -15px; top: 105px; }
        &-asteroid-2    { right:   90px; top:  10px; }
    }

    &-banner { padding: $narrow-gap 0; }
    &-banner-image {
        max-width: 100%;
        margin: $narrow-gap 0;
    }

    &-links { text-align: center; }
    //&-linkset { display: inline-block; }
    &-linkset { display: inline; }
    &-link {
        display: inline-block;
        margin: $narrow-gap;
        vertical-align: bottom;

        // generate style for each nav link color
        @for $i from 1 through length($nav-colors) {
            &-#{$i} { color: nth($nav-colors, $i); }
        }
    }
    &-icon {
        display: block;
        margin: 0 auto $narrow-gap;
    }
}
.footer {
    color: $meta-color;
    text-align: center;

    &-notice {
        line-height: $text-line-height;
        margin-bottom: $medium-gap;
    }
    &-line {
        display: inline-block;
    }

    &-badges { margin-bottom: $medium-gap; }
    &-badge {
        display: inline-block;
        margin: $narrow-gap;
    }

    &-counter {
        white-space: nowrap;
    }
}
.header:after, .footer:before {
    content: "";
    display: block;
    height: 19px;
    background: url("divider-1.gif") repeat-x;
    margin: $wide-gap 0;
}
