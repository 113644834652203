.collection {
    margin: $wide-gap 0;

    &-item {
        margin: $medium-gap 0;

        &:before, &:last-child:after {
            content: "";
            display: block;
            height: 2px;
            background: url("divider-2.gif") no-repeat center;
            margin: $medium-gap 0;
        }
    }
}
