.document {
    margin: $wide-gap 0;
    //background-color: $bg-color;
    //box-shadow: 0 0 $medium-gap $medium-gap $bg-color;
    //border-radius: $medium-gap;

    &-header {
        margin-bottom: $medium-gap;
    }
    &-header-hidden {
        display: none;
    }

    &-breadcrumbs {
        color: $meta-color;
        margin-bottom: $medium-gap;
        line-height: $text-line-height;
    }
    &-breadcrumb, &-breadcrumb-last {
        display: inline-block;
    }
    &-breadcrumb::after {
        content: "/";
        margin: 0 $narrow-gap;
    }

    &-date {
        color: $meta-color;
        line-height: $text-line-height;
    }

    &-footer {
        margin-top: $wide-gap;
    }
    &-nav {
        text-align: center;
        line-height: $text-line-height;
    }
    &-prev, &-next {
        display: inline-block;
        vertical-align: top;
        width: 50%;
    }
    &-prev { text-align: left;}
    &-prev-link { margin-right: $medium-gap; }
    &-next { text-align: right;} 
    &-next-link { margin-left: $medium-gap; }
}
