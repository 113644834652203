.video {
    max-width: $media-preview-width;
    margin: $medium-gap auto;
    border: $border-width $border-style $border-color;
    background: $bg-color;

    &-frame { position: relative; }

    // aspect ratio modifiers (padding-bottom = height / width * 100)
    &-frame-16-9 { padding-bottom: 56.25%; }
    &-frame-4-3 { padding-bottom: 75%; }

    &-media {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
